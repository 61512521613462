@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@200;400;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Playfair+Display&display=swap");

/* src/index.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  background-color: #88815f;
  padding-top: env(safe-area-inset-top);
}

/* Styling the scrollbar track (background) */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Change this to match your site's theme */
}

/* Styling the scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Change this to your preferred color */
  border-radius: 10px; /* Rounded corners on the scrollbar handle */
  border: 2px solid #f1f1f1; /* Add a border with the same color as the track for padding effect */
}

/* Styling the scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker shade on hover */
}

/* Styling the scrollbar width (and height for horizontal scrollbars) */
::-webkit-scrollbar {
  width: 10px; /* Width of the vertical scrollbar */
  height: 10px; /* Height of the horizontal scrollbar */
}

.safe-area {
  padding-top: env(safe-area-inset-top); /* Top safe area */
  padding-bottom: env(safe-area-inset-bottom); /* Bottom safe area */
  padding-left: env(safe-area-inset-left); /* Left safe area */
  padding-right: env(safe-area-inset-right); /* Right safe area */
  /* Add any additional styles you need here */
}

@media print {
  .back-button {
    display: none;
  }
}
